.App {
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
}

.navigation {
  /* color: rgba(0, 0, 0, 0); */
  text-decoration: none;
}

.jumbo {
  width: inherit;
  max-height: 75vh;
}

.topRowHome {
  margin-top: 8rem;
}

.pageTitle {
  font-size: 2rem;
}

.loading {
  align-content: center;
  animation:blinkingText 1.2s infinite;
}

.slideDown {
  /* align-content: center; */
  animation: slideInFromTop 3.2s;
}

.slideUp {
  /* align-content: center; */
  animation: slideInFromBottom 2.0s;
}

.loading:hover {
  animation: none;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }

  49% {
    color: #000;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: #000;
  }
}

.logoCenter {
  display: block;
  /* vertical-align: middle; */
  align-content: center;
  justify-content: space-between;
  align-self: center;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100);
  }
}

.slideRightToLeft {
  animation: slideInRtoL 4.0s
}

@keyframes slideInRtoL {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.slideLeftToRight {
  animation: slideInLtoR 4.0s
}

@keyframes slideInLtoR {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100);
  }
}


@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.homeBuckets {
  padding-bottom: 40px;
  padding-top: 40px;
  background-color: #ffffff;
  text-align: center;
  align-items: center;
  margin-bottom: 3em;
  color: black;
}

.bg-img {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("https://cannapedia.s3.amazonaws.com/img/cannapedia_favicon_transp.png") center center no-repeat;
  background-size: cover;
}

.bg-img:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #002f4b, #dc4225);
  opacity: .6;
}

.opacity-method {
  background-image: url("https://cannapedia.s3.amazonaws.com/img/cannapedia_favicon_transp.png");
  /* z-index: "100"; */
  position: relative;
  background-position: top;
  border: 2px solid #15c6a7;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cardstyle {
  font-size: 24px;
}

.abc {
  max-width: 10rem;
}

.abd {
  max-width: 7rem;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

tr .align-me {
  display: flex;
  align-items: center;
}

.center2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.pageHeader {
  font-size: 48px;
}

/* Stuff From Card Flips below */

.g-card {
  /* height: 20rem; */
  perspective: 1000px;
}

.g-card .card-container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  transition: ease-in 0.8s;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  /* border: 1px solid rgba(0,0,0,.125); */
  /* Commented the above out because it looks cooler without it */
}

/* .g-card:hover .card-container {
  transform: rotateY(180deg);
} */

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  color: black;
  border-radius: 5px;
  backface-visibility: hidden;
  overflow: hidden;
}

.card-front {
  font-size: larger;
}

.card-back {
  transform: rotateY(180deg);
  padding: 1rem;
  background: white;
  /* font-size: larger; */
  /* text-shadow: 2px 2px black;
  text-decoration-color: black; */
  /* background-color: grey; */
  /* background-image: 
  linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
    ),
    url('./cardBackground.jpg');  */
}

input[type="button"],
input[type="submit"],
input[type="reset"],
.button,
button {
  cursor: pointer
}

.bg-custom-nav {
  background-color: rgb(195, 243, 255);
}

.bg-custom-1 {
  background-color: rgba(48, 190, 213);
}

.bg-custom-2 {
  background-color: rgba(48, 190, 213, 0.8);
}

.bg-custom-3 {
  background-color: rgba(48, 190, 213, 0.6);
}

.bg-custom-4 {
  background-color: rgba(48, 190, 213, 0.4);
}

.bg-custom-5 {
  background-color: rgba(48, 190, 213, 0.2);
}

.bg-custom-6 {
  background-color: rgba(48, 190, 213, 0.1);
}

.bg-custom-7 {
  background-color: rgba(243, 192, 82);
}

.bg-custom-8 {
  background-color: rgba(243, 192, 82, 0.8);
}

.bg-custom-9 {
  background-color: rgba(243, 192, 82, 0.6);
}

.bg-custom-10 {
  background-color: rgba(243, 192, 82, 0.4);
}

.bg-custom-11 {
  background-color: rgba(243, 192, 82, 0.2);
}

.bg-custom-12 {
  background-color: rgba(243, 192, 82, 0.1);
}

.thead {
  position: sticky;
  top: -1px;
  /* background-color: #fff; */
}

.tbody {
  overflow-y: auto;
  max-height: 500px;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.rbc-event {
  font-weight: bold !important;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
  border: 1px #303030 !important;
  box-shadow: #303030 !important;
  margin-top: 0.75rem !important;
  background-color: #3030301a !important;
  border-radius: 0px !important;
  color: #303030 !important;
  text-align: center !important;
}